<template>
    <main>
        <div>
            <label class="font-weight-bolder">Años de escolaridad</label>
            <input type="number" class="form-control form-control-sm" v-model="years_school"
            :class="{
                'is-invalid': $v.years_school.$error && $v.years_school.$dirty
            }">
        </div>
        <div v-if="years_school !== ''" class="mt-2">
            <div v-for="item in preguntas" :key="item.id">
                <label class="font-weight-bold">{{ item.nombre }}</label>
                <table class="table table-striped table-bordered table-sm">
                    <tr class="bg-light">
                        <th>Pregunta</th>
                        <th>Puntuación</th>
                    </tr>
                    <tr v-for="pre in item.pregunta" :key="pre.id">
                        <td class="">{{ pre.texto }}</td>
                        <!-- <td>
                        <select class="form-control form-control-sm" v-model="pre.respuesta">
                            <option v-for="opc in pre.opciones" :key="`${opc.id}_opc`" :value="opc.valor">
                                {{opc.texto}}
                            </option>
                        </select>
                    </td> -->
                        <td>
                            <div class="container d-flex mx-2">
                                <label v-for="opc in pre.opciones" :key="`${opc.id}_opc`">
                                    <input type="radio" :value="opc.valor" :name="`radio_${opc.id}`"
                                        v-model="pre.respuesta" />
                                    <span>{{ opc.texto }}</span>
                                </label>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="mt-4 text-center d-flex justify-content-center ">
                <div class="col-5">
                    <div :class="`card ${clasificacion.class} shadow-none`">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1">
                                    <div class="h3 fw-bold text-bold mb-1">Puntaje Total: <span
                                            class="badge badge-white text-bold h2">{{ puntaje }}</span></div>
                                    <div class="h5 text-black-75">{{ clasificacion.text }}</div>
                                    <input type="hidden" class="form-control" v-model="puntaje" readonly>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="form-group col-12">
               <label class="font-weight-bold">Puntaje Total</label>
               <input type="hidden" class="form-control" v-model="puntaje" readonly>
               <span :class="clasificacion.class">{{clasificacion.text}}</span>
           </div> -->
            </div>
        </div>
        <div class="alert alert-danger text-center mt-2" v-if="$v.preguntas.$error && $v.preguntas.$dirty">Debe completar el
            formulario</div>
    </main>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import minimentalService from '../../../../services/minimentalService';
import respuestasMinimentalService from "../../../../services/respuestasMinimentalService";
import { isEmpty } from "lodash";
import Toast from "@/components/common/utilities/toast";

export default {
    props: ['idHistoria', 'finalizada', 'idUsuario', 'trimestre'],
    data() {
        return {
            usuario: {},
            preguntas: [],
            id_respuesta: '',
            years_school: '',
        };
    },
    validations() {
        return {
            years_school: { required },
            preguntas: {
                $each: {
                    pregunta: {
                        $each: {
                            respuesta: { required }
                        }
                    }
                }
            }
        }
    },
    methods: {

        async cargarPreguntas() {
            try {
                this.LoaderSpinnerShow();
                const response_respuesta = await respuestasMinimentalService.showByHc(
                    {
                        id_historia: this.idHistoria
                    }
                );
                if (!isEmpty(response_respuesta.data)) {
                    this.id_respuesta = response_respuesta.data.id;
                    this.years_school=response_respuesta.data.escolaridad;
                }

                const response = await minimentalService.index();

                this.preguntas = response.data.map(p => {

                    return {
                        ...p,
                        pregunta: p.preguntas.map(x => {
                            let respuesta = '';
                            if(isEmpty(response_respuesta.data) && this.years_school<=3){
                                respuesta = x.defaultOption
                            }
                            if (!isEmpty(response_respuesta.data)) {
                                respuesta = response_respuesta.data.detalles.find(res => parseInt(res.id_pregunta) === parseInt(x.id))?.valor || 0
                            }
                            return {
                                ...x,
                                respuesta: respuesta
                            }

                        })
                    }
                });
                this.LoaderSpinnerHide();
            } catch (error) {
                console.log(error);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }


        },
        async save() {
            try {

                this.$v.$touch();

                if (this.$v.$invalid) return;

                let respuestas = [];

                this.preguntas.forEach(x => {
                    respuestas.push(...x.pregunta);
                });

                let respuestas_minimental = {
                    puntaje: this.puntaje,
                    id_historia: this.idHistoria,
                    id_usuario: this.idUsuario,
                    respuestas: respuestas,
                    escolaridad: this.years_school
                };
                this.LoaderSpinnerShow();
                if (this.id_respuesta !== '') {
                    respuestas_minimental.id = this.id_respuesta;
                    await respuestasMinimentalService.update(respuestas_minimental);
                } else {
                    await respuestasMinimentalService.store(respuestas_minimental);
                }
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con éxito'
                });

            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
    },
    async created() {
        this.cargarPreguntas();
    },
    computed: {
        puntaje: function () {

            let total = 0

            this.preguntas.forEach(seccion => {
                total += seccion.pregunta.reduce((t, c) => {
                    if (c.respuesta === '') return Number(t);
                    return Number(t) + Number(c.respuesta);
                }, 0)
            });

            return total;

        },
        clasificacion() {

            const clasifications = {
                'bajo': {
                    code: 'bajo',
                    text: 'Probale Deterioro Cognitivo',
                    class: 'bg-danger-soft'
                },
                'alto': {
                    code: 'alto',
                    text: 'Sin Deterioro Cognitivo',
                    class: 'bg-success-soft'
                }
            };

            return this.puntaje > 24 ? clasifications['alto'] : clasifications['bajo'];

        }
    }
}
</script>
<style scoped>
.container form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.container label {
    display: flex;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    margin-bottom: 0.375em;
}

.container label input {
    position: absolute;
    left: -9999px;
}

.container label input:checked+span {
    background-color: #414181;
    color: white;
}

.container label input:checked+span:before {
    box-shadow: inset 0 0 0 0.4375em #00005c;
}

.container label span {
    display: flex;
    align-items: center;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 99em;
    transition: 0.25s ease;
    color: #414181;
    font-size: 12px;
}

.container label span:hover {
    background-color: #d6d6e5;
}

.container label span:before {
    display: flex;
    flex-shrink: 0;
    content: "";
    background-color: #fff;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    margin-right: 0.375em;
    transition: 0.25s ease;
    box-shadow: inset 0 0 0 0.125em #00005c;
}
</style>